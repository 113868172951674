import * as React from "react"
import { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout"
import Seo from "../components/seo"
import '../styles/mainpage.css';
import restaurant from '../images/restaurant-cafe.png'
import penthouses from '../images/penthouses-apartments.png'
import boutique from '../images/boutique-others.png'
import country from '../images/country-houses.png'
import icon1 from '../images/main-icon-1.png'
import icon2 from '../images/main-icon-2.png'
import icon3 from '../images/main-icon-3.png'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';

const IndexPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.querySelector("body").classList = ["index"];
    function opacity1() {
      if (typeof document !== "undefined") {
        document.getElementById('cover-wrapper')?.classList.add('timeout1')
      }
    }
    function opacity2() {
      if (typeof document !== "undefined") {
        document.getElementById('cover-wrapper')?.classList.add('timeout2')
      }
    }
    function opacity3() {
      if (typeof document !== "undefined") {
        document.getElementById('cover-wrapper')?.classList.add('timeout3')
      }
    }
    if (typeof window !== "undefined") {
      const timeout1 = setTimeout(opacity1, 500);
      const timeout2 = setTimeout(opacity2, 1000);
      const timeout3 = setTimeout(opacity3, 3000);
      window.onload = function () {
        timeout1();
        timeout2();
        timeout3();
      }
    }
  }, [])
  return (
    <Layout>
      <Seo title={t("AnasayfaSEO")} />
      <div className="w-full h-screen bg-[url('../images/kis-bahcesi.png')] bg-cover relative after:-translate-y-full before:absolute before:transition before:duration-300 before:left-0 before:h-full before:w-5/12 sm:before:w-full sm:before:opacity-20 before:-translate-x-full before:bg-slate-50 before:opacity-60 after:absolute after:transition after:duration-300 after:right-0 after:top-0 after:w-7/12 sm:after:w-full after:h-3/6 after:bg-white after:opacity-40" id="cover-wrapper">
        <div className="absolute left-0 flex items-end h-full px-24 transition duration-300 -translate-x-full bg-white pb-36 sm:bg-white/30 sm:px-0 sm:pb-7 sm:w-full sm:bottom-0 sm:h-fit sm:py-5 sm:justify-center sm:items-start discover-wrapper">
          <a href="#about" className="relative flex items-center text-xl rotate-90 cursor-pointer discover-btn text-default font-first sm:text-center sm:rotate-0 after:hidden">{t("Kesfet")}</a>
        </div>
        <div className="relative z-10 flex flex-col items-start justify-center ml-auto transition duration-500 -translate-x-full cover-text-wrapper cover-text h-90 w-77 sm:h-full sm:w-11/12 sm:mx-auto">
          <h1 className="text-8xl 2xl:text-7xl sm:text-3xl leading-big sm:leading-small sm:w-full sm:text-center text-default font-first">{t("Minimalist")}</h1>
          <h1 className="text-8xl 2xl:text-7xl sm:text-3xl leading-big sm:leading-small sm:w-full sm:text-center text-default font-first">{t("Modern")}</h1>
          <h1 className="text-8xl 2xl:text-7xl sm:text-3xl leading-big sm:leading-small sm:w-full sm:text-center text-defaultYellow font-first">{t("Yalin")}</h1>
          {/* <p className="text-3xl font-medium text-black opacity-60 font-second 2xl:text-2xl w-624 sm:leading-tight sm:text-lg sm:w-5/6 sm:mx-auto sm:text-center sm:opacity-100">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
        </div>
      </div>
      <div className="max-w-full mt-10 overflow-hidden wrapper" id="about">
        <div className="inline-block overflow-hidden whitespace-nowrap animate-marquee">
          <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl">{t("ModernKonforlu")}</p>
          <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl">{t("ModernKonforlu")}</p>
        </div>
      </div>
      <section className="mb-10 xl:mx-auto pl-60 about xl:pl-0 xl:w-full md:px-5">
        <p className="w-full max-w-5xl text-2xl text-black font-second opacity-80 xl:text-2xl md:text-lg">{t("MainAbout")}</p>
        <Link to="/hakkimizda" className="relative flex items-center text-xl transition duration-300 cursor-pointer w-fit after:border-t-blue-900 after:border font-first text-default md:text-lg after:absolute after:w-16 after:-right-20 after:transition after:duration-300 hover:after:-right-24">{t("DevaminiOku")}</Link>
      </section>
      <section className="flex flex-wrap justify-between w-full mt-40 projects md:mt-16 md:flex-col-reverse">
        <div className="flex flex-col w-6/12 pr-5 left md:w-full md:px-5">
          <Link to="/projelerimiz/restaurant-kafe" className="relative flex justify-center w-10/12 mx-auto overflow-hidden transition duration-300 rounded-full cursor-pointer group">
            <img src={restaurant} alt="" className="w-full" />
            <div className="absolute top-0 flex items-end justify-center w-full pb-3 text-5xl font-semibold text-center text-white transition -translate-y-full h-2/4 bg-default-30 group-hover:translate-y-0 font-first md:text-xl md:px-2">{t("RestoranKafe")}</div>
          </Link>
          <Link to="/projelerimiz/penthouse-daire" className="relative z-30 flex justify-center w-full overflow-hidden transition duration-300 cursor-pointer group md:-mt-0">
            <img src={penthouses} alt="" className="w-full" />
            <div className="absolute top-0 left-0 flex items-center justify-end w-6/12 h-full px-5 pb-3 text-5xl font-semibold text-right transition -translate-x-full text-default bg-white-50 group-hover:translate-x-0 font-first md:text-xl md:px-2">{t("PenthouseDaire")}</div>
          </Link>
        </div>
        <div className="flex flex-col w-6/12 pt-40 pl-5 right md:w-full md:px-5 md:pt-0">
          <h5 className="relative flex items-center mb-40 text-2xl font-normal font-first text-default after:border-t-blue-900 after:border after:absolute after:w-16 after:-right-20 after:transition after:duration-300 w-fit md:mb-5">{t("ProjectsMain")}</h5>
          <Link to="/projelerimiz/villa" className="relative z-30 flex justify-center w-full overflow-hidden transition duration-300 cursor-pointer group md:mb-10">
            <img src={country} alt="" className="w-full" />
            <div className="absolute top-0 right-0 flex items-center w-1/2 h-full px-5 pb-3 text-5xl font-semibold text-left transition translate-x-full text-default bg-white-50 group-hover:translate-x-0 font-first md:text-xl md:px-2">{t("VillaMain")}</div>
          </Link>
        </div>
      </section>
      <div className="relative w-full pt-40 before:ml-10 bg-softBrown -mt-percent before:absolute before:w-6/12 before:h-2/5 before:bg-softBrown before:left-0 before:z-20 before:top-12 md:before:hidden md:px-5 md:pt-28">
        <Link to="/projelerimiz/diger-projeler" className="relative z-10 flex justify-end w-9/12 ml-auto mr-0 overflow-hidden cursor-pointer full group md:w-full">
          <img src={boutique} alt="" className="w-full" />
          <div className="absolute bottom-0 flex items-start justify-center w-full py-5 pb-3 text-5xl font-semibold text-center text-white transition translate-y-full h-2/4 bg-default-30 group-hover:translate-y-0 font-first md:text-xl md:px-2">{t("OtherProjectsMain")}</div>
        </Link>
      </div>
      <div className="max-w-full py-10 overflow-hidden wrapper bg-softBrown">
        <div className="inline-block py-20 overflow-hidden pb-28 whitespace-nowrap animate-marquee md:py-3">
          <p className="inline-block font-thin leading-none cursor-default font-third text-whiteLoop xl:text-9xl md:text-7xl">{t("OzgunIslevsel")}</p>
          <p className="inline-block font-thin leading-none cursor-default font-third text-whiteLoop xl:text-9xl md:text-7xl">{t("OzgunIslevsel")}</p>
        </div>
      </div>
      <div className="flex items-start justify-between w-4/5 mx-auto mb-8 -mt-14 md:flex-col md:items-center md:justify-center md:-mt-16 md:px-5 md:w-full">
        <div className="flex flex-col items-start w-4/12 md:mb-3 md:w-full">
          <img src={icon1} alt="" className="mb-5 md:mx-auto md:-mb-2 md:scale-75" />
          <h6 className="mb-2 text-3xl font-semibold text-default font-second md:text-center md:text-2xl md:w-full">{t("YenilikciFikirler")}</h6>
          <p className="w-5/6 text-2xl text-default font-second md:text-xl md:w-full md:text-center">{t("YenilikciFikirlerText")}</p>
        </div>
        <div className="flex flex-col items-start w-4/12 md:mb-3 md:w-full">
          <img src={icon2} alt="" className="mb-5 md:mx-auto md:-mb-2 md:scale-75" />
          <h6 className="mb-2 text-3xl font-semibold text-default font-second md:text-center md:text-2xl md:w-full">{t("OzenliTasarimlar")}</h6>
          <p className="w-5/6 text-2xl text-default font-second md:text-xl md:w-full md:text-center">{t("OzenliTasarimlarText")}</p>
        </div>
        <div className="flex flex-col items-start w-4/12 md:mb-3 md:w-full">
          <img src={icon3} alt="" className="mb-5 md:mx-auto md:-mb-2 md:scale-75" />
          <h6 className="mb-2 text-3xl font-semibold text-default font-second md:text-center md:text-2xl md:w-full">{t("ProfesyonelEkip")}</h6>
          <p className="w-5/6 text-2xl text-default font-second md:text-xl md:w-full md:text-center">{t("ProfesyonelEkipText")}
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;